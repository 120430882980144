$white: #ffffff;

$font_color1:#1a171b;
$font_color2:#767676;
$accent_color:#ed1c24;

$dark_grey:#1c1c1c;

$special_font: 'Montserrat', sans-serif;
$main_font: 'Open Sans', sans-serif;



@mixin fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@mixin anim {
  transition: 0.3s all ease;
}

body{
  h1,h2,h3,h4,h5,h6,p,ul,ol,a{
    margin: 0;
    font-weight: 400;
  }
  ul{
    list-style: none;
    padding: 0;
  }
}

main.main{
  background: $white;
}

.more_btn{
  display: inline-block;
  position: relative;
  font-family: $special_font;
  font-size: 15px;
  line-height: 15px;
  font-weight: 600;
  color: $font_color1;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 20px 45px;
  z-index: 1;
  &:after{
    -webkit-transform: skew(-25deg);
    -moz-transform: skew(-25deg);
    -o-transform: skew(-25deg);
    content: '';
    background:#767676;
    @include fill;
    z-index: -1;
  }
}

.default_font{
  font-size: 15px;
  line-height: 30px;
  @media only screen and (max-width: 1500px) {
    font-size: 13px;
    line-height: 27px;
  }
  h1{
    font-size: 72px;
    line-height: 84px;
    @media only screen and (max-width: 1500px) {
      font-size: 48px;
      line-height: 60px;
    }
  }
  h2{
    font-size: 48px;
    line-height: 60px;
    @media only screen and (max-width: 1500px) {
      font-size: 24px;
      line-height: 40px;
    }
  }
  h3{
    font-size: 24px;
    line-height: 40px;
    @media only screen and (max-width: 1500px) {
      font-size: 22px;
      line-height: 38px;
    }
  }
  h4{
    font-size: 22px;
    line-height: 38px;
    @media only screen and (max-width: 1500px) {
      font-size: 20px;
      line-height: 36px;
    }
  }
  h5{
    font-size: 20px;
    line-height: 36px;
    @media only screen and (max-width: 1500px) {
      font-size: 18px;
      line-height: 34px;
    }
  }
  h6{
    font-size: 18px;
    line-height: 34px;
    @media only screen and (max-width: 1500px) {
      font-size: 15px;
      line-height: 25px;
    }
  }
  ul{
    padding-left: 18px;
    list-style: disc;
  }
  ol{
    padding-left: 18px;
  }
  h1,h2,h3,h4,h5,h6,p,ul,ol,a{
    margin-bottom: 30px;
    @media only screen and (max-width: 1500px) {
      margin-bottom: 15px;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.vertical_align{
  @include fill;
  > div{
    display: table;
    height: 100%;
    width: 100%;
    > div{
      display: table-cell;
      vertical-align: middle;
      height: 100%;
    }
  }
}

.layer{
  position: absolute;
  top: 0;
  left: 0;
  height: 620px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('./../img/layer_bg.jpg');
}

.page{
  position: relative;
  z-index: 2;
}

main.main {

}

section.slider{
  div.slides{
    height: 680px;
    width: 100%;
    position: relative;
    background: #000000;
    @media only screen and (max-width: 1500px) {
      height: 500px;
    }
    > ul{
      @include fill;
      > li{
        @include fill;
        background-repeat: no-repeat;
        background-position: center;
        background-image:url('./../img/slider_image.jpg');
        div.slider_content{
          z-index: 4;
          font-family: $special_font;
          position: relative;
          @media only screen and (max-width: 991px) {
            text-align: center;
          }
          div{
            &.desc{
              padding: 100px 0 0 70px;
              @media only screen and (max-width: 1500px) {
                padding: 50px 0 0 35px;
              }
              @media only screen and (max-width: 991px) {
                padding: 30px 15px 0 15px;
                p{
                  display: none;
                }
              }
              header{
                font-size: 18px;
                line-height: 35px;
                color: #afafaf;
                margin-bottom: 50px;
                @media only screen and (max-width: 1500px) {
                  margin-bottom: 30px;
                }
                @media only screen and (max-width: 991px) {
                  font-size: 13px;
                  line-height: 27px;
                }
                div.default_font{
                  color: $white;
                  text-transform: uppercase;
                  h1{
                    font-size: 48px;
                    line-height: 60px;  
                    letter-spacing: 1px;
                    font-weight: 600;
                    margin-bottom: 20px;
                    @media only screen and (max-width: 1500px) {
                      font-size: 30px;
                      line-height: 50px;
                    }
                    @media only screen and (max-width: 991px) {
                      font-size: 24px;
                      line-height: 40px;
                    }
                    strong{
                      font-weight: 600;
                      color:$accent_color;
                    }
                  }
                }
              }
              div.price{
                font-weight: 600;
                @media only screen and (max-width: 1200px) {
                  > div{
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 50px;
                    &:last-child{
                      margin-right: 0;
                    }
                    @media only screen and (max-width: 991px) {
                      width: 50%;
                      text-align: center;
                      margin-right: 0;
                    }
                  }
                }
                p{
                  font-weight: inherit;
                }
                div.old_price{
                  color: #767676;
                  font-size: 30px;
                  margin-bottom: 10px;
                  @media only screen and (max-width: 1500px) {
                    font-size: 25px;
                  }
                  @media only screen and (max-width: 1200px) {
                    margin-bottom: 0;
                  }
                  @media only screen and (max-width: 991px) {
                    font-size: 18px;
                  }
                  > p{
                    display: inline-block;
                    position: relative;
                    &:after{
                      content: '';
                      border-bottom: 2px solid #ed1c24;
                      width: 140%;
                      transform: rotate(160deg);
                      display: block;
                      position: absolute;
                      top: 50%;
                      left: -20%;
                    }
                  }
                }
                div.new_price{
                  font-size: 72px;
                  color: $white;
                  @media only screen and (max-width: 1500px) {
                    font-size: 50px;
                  }
                  @media only screen and (max-width: 991px) {
                    font-size: 30px;
                  }
                  > p{
                    display: inline-block;
                    border-bottom: 3px solid $font_color2; 
                  }
                }
              }
            }
            &.image_holder{
              padding-top: 10%;
              div.image{
                margin-right: auto;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% auto;
                width: 75%;
                height: 0;
                padding-bottom: 75%; 
                @media only screen and (max-width: 1500px) {
                  margin-left: auto;
                }
                @media only screen and (max-width: 991px) {
                  width: 217px;
                  padding-bottom: 217px;
                }
              }
            }
          }
        }
        div.cover{
          @include fill;
          background-repeat: no-repeat;
          background-position: center bottom;
          background-size: cover;
          background: url('./../img/slider_cover.png');
          @media only screen and (max-width: 991px) {
            display: none;
          }
        }
      }
    }
    .slider_nav{
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 40px;
      left: 0;
      ul{
        li{
          display: inline-block;
          vertical-align: middle;
          cursor: pointer;
          &.dots{
            ul{
              padding: 0 30px;
              li{
                width: 12px;
                height: 0;
                padding-bottom: 12px;
                border-radius: 50%;
                background: #525658;
                margin-right: 18px;
                &:last-child{
                  margin-right: 0;
                }
                &.on{
                  background: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}

header.section_header{
  text-align: center;
  margin: 50px 0;
  font-family: $special_font;
  h2{
    font-size: 24px;
    line-height: 44px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
  }
}

section.last_products{
  div.carousel1{
    overflow: hidden;
    > ul{
      position: relative;
      white-space: nowrap;
      margin-bottom: 40px;
      > li{
        white-space: normal;
        display: inline-block;
        vertical-align: top;
        width: 22.6%;
        margin-right:3.2%;
        @media only screen and (max-width: 991px) {
          width: 100%;
        }
        div{
          &.image{
            width: 100%;
            height: 0;
            padding-bottom:115%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            @media only screen and (max-width: 991px) {
              padding-bottom: 100%;
            }
          }
          &.desc{
            color: $font_color1;
            div.default_font{
              line-height: 25px;
              padding: 15px 0;
              border-bottom: 1px solid $accent_color;
            }
            div.price{
              padding-top: 15px;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

section.promoted{
  div.carousel2{
    overflow: hidden;
    > ul{
      position: relative;
      white-space: nowrap;
      > li{
        white-space: normal;
        display: inline-block;
        vertical-align: top;
        .cover{
          .desc{
            color: $white;
            font-size: 24px;
            line-height: 34px;
            text-transform: uppercase;
            padding: 50px 0px 0 40px;
          }
          .more{
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 40px;
          }
        }
      }
    }
  }
}

section.promoted{
  margin-bottom: 50px;
  div.carousel2{
    overflow: hidden;
   > ul{
     position: relative;
     white-space: nowrap;
    margin-bottom: 40px;
     > li{
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 31.5%;
        height: 0%;
        padding-bottom: 36%;
        margin-right: 2.7%;
        position: relative;
        @media only screen and (max-width: 991px) {
          width: 100%;
          padding-bottom: 100%;
        }
        .cover{
          @include fill;
          background-repeat: no-repeat;
          background-position: left top;
          background-size: cover;
          background-image: url('./../img/promoted_cover.png');
        }
      }
    }
  }
}

div.carousel_nav{
  text-align: center;
  > ul{
    > li{
      display: inline-block;
      vertical-align: top;
      width: 70px;
      height: 50px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #e8e8e8;
      margin-right: 4px;
      @include anim;
      &:last-child{
        margin-right: 0;
      }
      &.arr_left{
        background-image: url('./../img/carousel_arr_left.png');
        &:hover{
          background-color: $accent_color;
        }
      }
      &.arr_right{
        background-image: url('./../img/carousel_arr_right.png');
        &:hover{
          background-color: $accent_color;
        }
      }
    }
  }
}

section.short_about{
  width: 100%;
  height: 625px;
  display: table;
  background: $dark_grey;
  @media only screen and (max-width: 991px) {
      height: auto;
      display: block;
  }
  > div{
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    @media only screen and (max-width: 991px) {
        width: 100%;
        display: block;
    }
    &.image{
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 55%;
      @media only screen and (max-width: 991px) {
        width: 100%;
      }
    }
    &.desc{
      padding-left: 60px;
      width: 45%;
      color: $font_color2;
      padding-right: 60px;
      @media only screen and (max-width: 991px) {
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-right: 15px !important;
        padding-left: 15px !important;
        text-align: center;
      }
      header{
        text-transform: uppercase;
        margin-bottom: 20px;
        color: $white;
        @media only screen and (max-width: 1500px) {
          margin-bottom: 20px;
         div.default_font{
           margin-bottom: 0;
         }
        }
      }
      .default_font{
        margin-bottom: 15px;
        p{
          margin-bottom: 15px;
        }
      }
    }
  }
}
.text-right{
  @media only screen and (max-width: 991px) {
    text-align: center
  }
}

